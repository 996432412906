<!--
--------------------------------------------------------------------------------
<copyright file="DownloadData.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <details>
    <summary>
      <p class="list2">{{ $t('widget_info_text.download_data') }}</p>
    </summary>
    <p class="list text">
      {{ $t('widget_info_text.download_data_txt') }}<img src="images/info/downloaData.jpg" />
    </p>
  </details>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class DownloadDataInfo extends Vue {}
</script>
