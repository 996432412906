<!--
--------------------------------------------------------------------------------
<copyright file="LaserOutputAndScrapTimeline.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>
        {{ $t('widget_info_text.laser_output_and_scrap_timeline.non_stacked_lines_title') }}
      </summary>
      <details>
        <summary>{{ $t('widget_info_text.X-axis') }}</summary>
        <p class="text">{{ $t('widget_info_text.scale_selected_period') }}</p>
        <p class="text">{{ $t('widget_info_text.output_scrap_hist_xaxis_txt') }}</p>
        <p class="tip tip-bkg">{{ $t('widget_info_text.tip') }}:</p>
        <ul class="list tip-bkg">
          <li class="list-item2">{{ $t('widget_info_text.tip_hist_txt') }}</li>
          <li class="list-item2">{{ $t('widget_info_text.tooltip_txt') }}</li>
        </ul>
      </details>
      <details>
        <summary>{{ $t('widget_info_text.Y-axis') }}</summary>
        <p class="text">{{ $t('widget_info_text.outp_scrap_yaxis_txt') }}</p>
      </details>
      <details>
        <summary>{{ $t('widget_info_text.lines') }}</summary>
        <p class="text">{{ $t('widget_info_text.output_scrap_hist_lines') }}</p>
        <ul class="list">
          <li class="list-item">
            <span class="demi-bold" style="text-transform: lowercase">
              {{ $t('widget_info_text.scrap_lines') }} </span
            >:
            {{ $t('widget_info_text.scrap_lines_txt') }}
          </li>
          <li class="list-item">
            <span class="demi-bold" style="text-transform: lowercase">
              {{ $t('widget_info_text.output_lines') }} </span
            >:
            {{ $t('widget_info_text.output_lines_txt') }}
          </li>
        </ul>
      </details>
    </details>

    <details>
      <summary>
        {{ $t('widget_info_text.laser_output_and_scrap_timeline.stacked_columns_title') }}
      </summary>
      <details>
        <summary>{{ $t('widget_info_text.X-axis') }}</summary>
        <p class="text">{{ $t('widget_info_text.scale_selected_period') }}</p>
        <p class="text">{{ $t('widget_info_text.output_scrap_hist_xaxis_txt') }}</p>
        <p class="tip tip-bkg">{{ $t('widget_info_text.tip') }}:</p>
        <ul class="list tip-bkg">
          <li class="list-item2">{{ $t('widget_info_text.tip_hist_txt') }}</li>
          <li class="list-item2">{{ $t('widget_info_text.tooltip_txt') }}</li>
        </ul>
      </details>
      <details>
        <summary>{{ $t('widget_info_text.Y-axis') }}</summary>
        <p class="text">{{ $t('widget_info_text.outp_scrap_yaxis_txt') }}</p>
      </details>
      <details>
        <summary>{{ $t('widget_info_text.lines') }}</summary>
        <p class="text">{{ $t('widget_info_text.output_scrap_hist_lines') }}</p>
        <ul class="list">
          <li class="list-item">
            <span class="demi-bold" style="text-transform: lowercase">
              {{ $t('widget_info_text.scrap_lines') }} </span
            >:
            {{ $t('widget_info_text.scrap_lines_txt') }}
          </li>
          <li class="list-item">
            <span class="demi-bold" style="text-transform: lowercase">
              {{ $t('widget_info_text.output_lines') }} </span
            >:
            {{ $t('widget_info_text.output_lines_txt') }}
          </li>
        </ul>
      </details>
    </details>

    <details>
      <summary>
        {{ $t('widget_info_text.laser_output_and_scrap_timeline.raw_material_utilization.title') }}
      </summary>
      <details>
        <summary>{{ $t('widget_info_text.X-axis') }}</summary>
        <p class="text">{{ $t('widget_info_text.scale_selected_period') }}</p>
        <p class="tip tip-bkg">{{ $t('widget_info_text.tip') }}:</p>
        <ul class="list tip-bkg">
          <li class="list-item2">{{ $t('widget_info_text.tip_hist_txt') }}</li>
          <li class="list-item2">{{ $t('widget_info_text.tooltip_txt') }}</li>
        </ul>
      </details>
      <details>
        <summary>{{ $t('widget_info_text.Y-axis') }}</summary>
        <p class="text">{{ $t('widget_info_text.outp_scrap_yaxis_txt') }}</p>
      </details>
      <details>
        <summary>{{ $t('widget_info_text.bars') }}</summary>
        <p class="text">
          {{
            $t('widget_info_text.laser_output_and_scrap_timeline.raw_material_utilization.bars_txt')
          }}
        </p>
      </details>
      <details>
        <summary>{{ $t('widget_info_text.lines') }}</summary>
        <p class="text">
          {{
            $t('widget_info_text.laser_output_and_scrap_timeline.raw_material_utilization.line_txt')
          }}
        </p>
      </details>
    </details>

    <details>
      <summary>{{ $t('widget_info_text.icons') }}</summary>
      <time-range-filter />
      <shift-filter />
      <group-x-axis-by />
      <details>
        <summary>
          <span class="list">{{ $t('widget_info_text.more_filter') }}</span>
        </summary>
        <img src="images/info/threeDotsMenu.jpg" class="list" />
        <download-data />
        <aggregates />
        <change-display :hasRawMaterial="true" />
      </details>
    </details>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
import TimeRangeFilterInfo from '@/components/Charts/widgetInfo/TimeRangeFilter.vue';
import ShiftFilterInfo from '@/components/Charts/widgetInfo/ShiftFilter.vue';
import DownloadDataInfo from './DownloadData.vue';
import GroupXAxisByInfo from './GroupXAxisBy.vue';
import AggregatesInfo from './Aggregates.vue';
import ChangeDisplayInfo from './ChangeDisplay.vue';

@Component({
  components: {
    'time-range-filter': TimeRangeFilterInfo,
    'shift-filter': ShiftFilterInfo,
    'download-data': DownloadDataInfo,
    'group-x-axis-by': GroupXAxisByInfo,
    aggregates: AggregatesInfo,
    'change-display': ChangeDisplayInfo,
  },
})
export default class LaserOutputAndScrapTimelineInfo extends Vue {}
</script>

<style lang="scss" scoped>
// TODO: Move to WidgetInfo
details > details > summary {
  padding-left: calc(0.625rem + 30px) !important; // !important: can't win the original rule
}
</style>
