<!--
--------------------------------------------------------------------------------
<copyright file="TimeRangeFilter.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <details>
    <summary>
      <p class="list">{{ $t('widget_info_text.time_range_filter') }}</p>
    </summary>
    <img src="images/info/timeRangeFilter.jpg" class="list" />
    <p class="list text">{{ $t('widget_info_text.time_range_filter_calendar') }}</p>
    <img src="images/info/timeRangeCalendar.jpg" class="list" />
    <p class="list text">{{ $t('widget_info_text.time_filter_txt') }}</p>
  </details>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class TimeRangeFilterInfo extends Vue {}
</script>
